<template>
  <div class="scan-page">
    <div class="scan-page__navbar primary text-center py-10">
      <img
        @click="$router.push('/')"
        class="cursor-pointer"
        :src="require('@/assets/images/global/logo.svg')"
        alt=""
      />
    </div>
    <div class="scan-page__content d-flex align-center justify-center h-100">
      <div class="text-center" ref="qrcode">
        <QRCanvas id="canvas" :options="options"></QRCanvas>
      </div>
    </div>
  </div>
</template>

<script>
const { QRCanvas } = require("qrcanvas-vue");

export default {
  components: {
    QRCanvas,
  },
  data: () => ({
    data: "",
    code: "Fpi1or",
  }),
  computed: {
    options() {
      return {
        cellSize: 15,
        data: this.data,
      };
    },
  },
  methods: {},
  async created() {
    this.data = Math.random();
  },
};
</script>

<style lang="scss">
.scan-page {
  &__navbar {
    height: 15vh;
    width: 100%;
  }

  &__content {
    height: 85vh;
    width: 100%;
  }
}
</style>
